import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import Logo from "../assets/logo.svg"
import { graphql, useStaticQuery } from "gatsby"
import { Navbar, Nav, Media } from "react-bootstrap"
import { CTAButton } from "./cat-button"

const Header = props => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `
  )

  const menuItemList = data.site.siteMetadata.menuLinks

  return (
    <header
      style={{
        background: `rebeccapurple`,
        marginBottom: `1.45rem`,
      }}
    >
      <Navbar id="navbar" bg="light" expand="lg" fixed="top">
        <Navbar.Brand href="#home">
          <Logo id="brand-logo" width="35px" alt="logo" />
        </Navbar.Brand>
        <Navbar.Brand>
          <div id="brand-name">
            Zahnarztpraxis
            <br />
            Schmiegel-Obermann
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-between"
        >
          <Nav id="nav-links">
            {menuItemList.map((item, index) => (
              <Nav.Link key={index} href={`${item.link}`}>
                {item.name}
              </Nav.Link>
            ))}
          </Nav>
          <Nav id="action-links" className="d-none d-lg-flex ">
            <CTAButton />
            <Nav.Link href="tel:+49-3573-2355" className="phone nav-action">
              <FontAwesomeIcon icon={faPhoneAlt} /> +49 3573 2355
            </Nav.Link>
            <Nav.Link
              href="https://www.google.com/maps/dir//Rudolf-Breitscheid-Stra%C3%9Fe+37,+Senftenberg"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-action"
            >
              <Media>
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="align-self-center"
                  style={{ marginRight: "10px" }}
                />
                <Media.Body>
                  Rudolf-Breitscheidstraße 37
                  <br />
                  01968 Senftenberg
                </Media.Body>
              </Media>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import React from "react"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"

export const CTAButton = ({ className = "" }) => {
  const handleCallToAction = () => {
    window.toggleDrFlexAppointments()
  }
  return (
    <Button
      variant="secondary"
      className={`call-to-action ${className}`}
      onClick={handleCallToAction}
    >
      <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: "10px" }} />
      Termine online buchen
    </Button>
  )
}
